@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
/* You can add global styles to this file, and also import other style files */

html, body, app-root {
  overflow-x: hidden;
  @apply bg-gray-50;
  height: 100%;
  width: 100%;
}

body {
  @apply grid;
  margin: 0;
}

//app-root{
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//}

html, body, * {
  font-family: 'Open Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.cc-btn.cc-deny {
  display: none;
}

.cc-banner.cc-bottom {
  text-align: center;
  margin: auto;
}

.cc-btn.cc-allow {
  width: 15em;
}
.cc-message {
  .cc-link {
    display: none;
  }
}


